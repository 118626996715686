@font-face {
  font-family: "Axiforma";
  src: local("Axiforma"), url(./Axiforma-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Axiforma";
  src: local("Axiforma"), url(./Axiforma-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Axiforma";
  src: local("Axiforma"), url(./Axiforma-Medium.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Axiforma";
  src: local("Axiforma"), url(./Axiforma-SemiBold.ttf) format("truetype");
  font-weight: 600;
}
